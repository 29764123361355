import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Gallery from "@browniebroke/gatsby-image-gallery"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
  query ImagesForGallery {
    allFile(sort: {fields: name}, filter: {relativeDirectory: {eq: "galerie"}}) {
    nodes {
      name
      childImageSharp {
        thumb: gatsbyImageData(
          width: 270
          height: 270
          placeholder: BLURRED
        )
        full: gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    }
  }
`)

  const images = data.allFile.nodes
    .map((node) => {
      const title = getTitleFromFilename(node.name)

      return {
                    full:node.childImageSharp.full,
                    thumb: node.childImageSharp.thumb,
                    caption: title,
                    thumbAlt: node.name
    }})

  function getTitleFromFilename (fileName) {
    let titleName;

    if(fileName.indexOf("krippe-duerrenwaldstetten-1-")!==-1) {
      titleName = fileName.substring(fileName.indexOf("krippe-duerrenwaldstetten-1-")+28);
    }
    else if(fileName.indexOf("krippe-duerrenwaldstetten-z-")!==-1) {
      titleName = fileName.substring(fileName.indexOf("krippe-duerrenwaldstetten-z-")+28);
    }
    else {
      titleName = fileName.substring(fileName.indexOf("krippe-duerrenwaldstetten-")+26);
    }

    titleName = titleName.split("-")
      .map(name => name.replace('ae','ä'))
      .map(name => name.replace('oe','ö'))
      .map(name => name.replace('ue','ü'))
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return titleName;
  }

  return (
    <Layout id="galerie">
      <Seo title="Galerie der Weihnachtskrippe Dürrenwaldstetten" />
      <h1>Galerie der Weihnachtskrippe Dürrenwaldstetten</h1>
      <p className="normal">Hier können Sie ein paar ausgewählte Bilder der einzelnen Szenen und Figuren der Weihnachtskrippe betrachten.
        Wir bedanken uns recht herzlich bei Hr. Weindl für die Bereitstellung der Bilder.
      </p>
      <Gallery images={images}  colWidth="50" />
      <Link className="link-back" to="/">Zurück</Link>
    </Layout>
  )
}

export default GalleryPage
